
  import FileMetaData from "@/components/shared/FileMetaData.vue"
  import CareGiverInstanceEdit from "@/components/shared/CareGiverInstanceEdit.vue"
  import { defineComponent, ref, computed, getCurrentInstance } from "vue"
  import { CareGiverInstance } from "@/models/care_giver_instances";
  import { CareGiver } from "@/models/care_givers";
  import { FileMetaData as FMDModel } from "@/models/file_meta_datas";
  import { ResourceObject } from '@/models/jsonapi'
  import { apiDataMorpher, extractRelationshipIds, extractIncludedTypes } from "@/utils/apiDataHelper"
  import { fullName } from "@/utils/dataExtractors"
  import { shortDate } from "@/utils/helper"

  interface Props {
    id: string
  }

  export default defineComponent({
    name: "CareGiverInstanceShow",
    components: {
      FileMetaData,
      CareGiverInstanceEdit
    },
    props: {
      id: {
        type: String,
        required: true,
      }
    },
    setup(props: Props) {
      const root = getCurrentInstance().proxy
      const careGiverInstance = computed<ResourceObject<string, CareGiverInstance>>(() => root.$store.state.care_giver_instances.element)
      const careGiver = computed<ResourceObject<string, CareGiver>>(() => root.$store.state.care_giver_instances.element?.attributes.care_giver)
      const fmds = computed<ResourceObject<string, FMDModel>[]>(() => root.$store.state.care_giver_instances.element.attributes?.file_meta_datas)
      const bkEditModal = ref()
      const refreshData = () => {
        root.$store.dispatch('care_giver_instances/get', { id: props.id, queryParams: { include: 'care_giver,file_meta_datas' } })
      }
      const uiCareGiver = computed(() => {
        return { gender: careGiver.value?.attributes?.gender === 'm' ? "Herr" : "Frau" }
      })
      refreshData()
      const updateFiles = async (data:any) => {
        root.$store.dispatch('care_giver_instances/edit', { id: props.id, body: data })
        refreshData()
      }
      const titleActions = ref([
        {
          title: 'Edit',
          icon: 'pencil-alt',
          tooltip: 'Betreuungskraft bearbeiten',
          onClick: () => bkEditModal.value.show()
        },
      ])
      const bkUpdated = () => {
        bkEditModal.value.hide()
        refreshData()
      }
      return {
        bkEditModal,
        titleActions,
        careGiver,
        uiCareGiver,
        careGiverInstance,
        fmds,
        fullName,
        refreshData,
        updateFiles,
        bkUpdated,
        shortDate
      }
    }
  })
