import { render, staticRenderFns } from "./CareGiverInstance.vue?vue&type=template&id=76eef56f&scoped=true&lang=pug"
import script from "./CareGiverInstance.vue?vue&type=script&lang=ts"
export * from "./CareGiverInstance.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76eef56f",
  null
  
)

export default component.exports