
  import Vue from 'vue'
  import { defineComponent, reactive, ref, getCurrentInstance } from "vue"
  import { ResourceObject } from '@/models/jsonapi'
  import FormStepper from "@/components/shared/FormStepper.vue"
  import SchemaForm from "@/components/shared/form/SchemaForm.vue"
  import { CareGiverInstance } from "@/models/care_giver_instances";

  interface Props {
    careGiverInstance: ResourceObject<string, CareGiverInstance>
  }

  export default defineComponent({
    components: {
      FormStepper,
      SchemaForm
    },
    props: {
      careGiverInstance: {
        type: Object,
        required: true
      }
    },
    setup(props, { emit }) {
      const root = getCurrentInstance().proxy
      const careGiverInstanceFormSchema = {
        $schema: "https://json-schema.org/draft/2019-09/schema",
        $id: "https://crm.pflegehilfe-senioren.de/caregiverinstance-model.json",
        type: "object",
        properties: {
          gs: { type: "integer", title: "Deutschkenntnisse", minimum: 1, maximum: 6 },
          exp: { type: "string", title: "Erfahrung (gesamt)" },
          expGer: { type: "string", title: "Erfahrung (in Deutschland)" },
          description: { type: "string", title: "Beschreibung" },
          phone: { type: "string", title: "Telefonnummer" },
          birthday: { type: "string", title: "Geburtstag", format: "date" },
          // fileMetaDatas: {
          //   title: "Dateien",
          //   type: "array",
          //   items: {
          //     type: "object",
          //     properties: {
          //       id: { type: "string" }
          //     }
          //   }
          // },
        },
        required: ["gs", "exp", "expGer", "description", "phone", "birthday"]
      }

      const careGiverInstanceUISchema = {
        $schema: "https://json-schema.org/draft/2019-09/schema",
        $id: "https://crm.pflegehilfe-senioren.de/agency-view.json",
        $vocabulary: {
          "https://json-schema.org/draft/2019-09/vocab/core": true,
          "https://crm.pflegehilfe-senioren.de/ui-vocab": true
        },
        "ui:type": "form",
        "ui:widget": "b-row",
        type: "object",
        properties: {
          gs: {
            $ref: "https://crm.pflegehilfe-senioren.de/caregiverinstance-model.json#/properties/gs",
            "ui:label": "Deutschkenntnisse",
            "ui:widget": "b-form-radio-group",
            "ui:options": {
              options: [
                { text: "Dk1", value: 1 },
                { text: "Dk2", value: 2 },
                { text: "Dk3", value: 3 },
                { text: "Dk4", value: 4 },
                { text: "Dk5", value: 5 },
                { text: "Dk6", value: 6 },
              ]
            }
          },
          exp: {
            $ref: "https://crm.pflegehilfe-senioren.de/caregiverinstance-model.json#/properties/exp",
            "ui:label": "Erfahrung (gesamt)"
          },
          expGer: {
            $ref: "https://crm.pflegehilfe-senioren.de/caregiverinstance-model.json#/properties/expGer",
            "ui:label": "Erfahrung (in Deutschland)"
          },
          description: {
            $ref: "https://crm.pflegehilfe-senioren.de/caregiverinstance-model.json#/properties/description",
            "ui:label": "Beschreibung",
            "ui:widget": "html-element",
          },
          phone: {
            $ref: "https://crm.pflegehilfe-senioren.de/caregiverinstance-model.json#/properties/phone",
            "ui:label": "Telefonnummer"
          },
          birthday: {
            $ref: "https://crm.pflegehilfe-senioren.de/caregiverinstance-model.json#/properties/birthday",
            "ui:label": "Geburtstag",
            "ui:widget": "datetime",
            "ui:options": {
              type: "date"
            },
          },
          // fileMetaDatas: {
          //   type: "array",
          //   $ref: "https://crm.pflegehilfe-senioren.de/caregiverinstance-model.json#/properties/fileMetaDatas",
          //   "ui:type": "collection",
          //   "ui:label": "Dateien",
          //   "ui:widget": "uppyComponent",
          //   items: {
          //     $ref: "https://crm.pflegehilfe-senioren.de/caregiverinstance-model.json#/properties/fileMetaDatas/items",
          //     type: "object",
          //     properties: {
          //       id: {
          //         $ref: "https://crm.pflegehilfe-senioren.de/caregiverinstance-model.json#/properties/fileMetaDatas/items/properties/id"
          //       }
          //     }
          //   }
          // },
        }
      }

      // const form = ref<ResourceObject<string, CareGiverInstance>>(props.careGiverInstance)
      const form = ref(props.careGiverInstance.attributes)
      const sendForm = async () => {
        await root.$store.dispatch('care_giver_instances/edit', { id: props.careGiverInstance.id, body: { attributes: form.value } })
        emit('refresh')
      }

      return {
        form,
        careGiverInstanceFormSchema,
        careGiverInstanceUISchema,
        sendForm,
      }
    }
  })
